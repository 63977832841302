import React, { useEffect, useState, useCallback } from "react";
import { Header, SiteModal, PrimaryButton } from "../../components/common";
import PageWrapper from "../PageWrapper";
import { constants, Utils, roles, AppDefaults } from "../../helpers";
import { useAppsStore } from "../../store/AppsStore";
import { useOrganizations } from "../../store/OrganizationsStore";
import DefaultPolicies from "../../store/reducers/DefaultPolicies";
import { usePoliciesStore } from "../../store/policiesStore";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { ReactComponent as BtnCopy } from "../../assets/images/btn_copy.svg";
import { ReactComponent as LicenseExpireWarning } from "../../assets/images/LicenseExpireWarning.svg";
import { ReactComponent as InfoCircleIcon } from "../../assets/images/Info-circle.svg";
import { RxCross1 } from "react-icons/rx";
import { HiExclamationCircle } from "react-icons/hi2";
import { useLoggedInUserData } from "../../store/LoggedInAccountStore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  // getAllDevicesData,
  getLocationsData,
  // getSelectedOrganization,
  // setAllDevicesData,
  setFilteredDevicesData,
  setLocationsData,
  getTOSPolicyAcceptionFlag,
} from "../../store/reducers/AccountReducer";
import { useDispatch, useSelector } from "react-redux";
// import { setDevicesListOfCurrOrg } from '../../store/reducers/NVRDeviceReducer';
import moment from "moment";
import useLicensesStore from "../../store/LicensesStore";
import { getCustomerOrgData } from "../../store/OrganizationsStoreIDB";
import { getLoggedInUserRole } from "../../store/reducers/OrganizationsReducer";
import {
  getSelectedOrganization,
  setAllDevicesData,
  getAllDevicesData,
} from "../../store/AccountStoreIDB";
import { observerInstance } from "../../store/indexDB/observer";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import { setDevicesListOfCurrOrg } from "../../store/NVRDeviceStoreIDB";
import { setCustomerOrgDevices } from "../../store/CustomerOrgDevicesStoreIDB";

function LandingPage() {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const getApps = useAppsStore((state) => state.getApps);
  let appList = useAppsStore((state) => state.appList);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  let orgName = searchParams.get("orgName");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [licensesData, setLicensesData] = useState([]);
  const [tooltipOrgNameVisible, setTooltipOrgNameVisible] = useState(false);
  const [hoverTooltipOrgNameVisible, setHoverTooltipOrgNameVisible] =
    useState(false);
  const [hoverTooltipOrgIDVisible, setHoverTooltipOrgIDVisible] =
    useState(false);
  const locationDetails = useSelector(getLocationsData);
  const [filteredExpiredLicenses, setFilteredExpiredLicenses] = useState([]);
  const [showExpiredLicenseWarning, setShowExpiredLicenseWarning] =
    useState(false);
  const [actionExpiredLicenseWarning, setActionExpiredLicenseWarning] =
    useState(false);
  const [showLicenseExpiredWarning, setShowLicenseExpiredWarning] =
    useState(true);
  const [warningShown, setWarningShown] = useState(false);
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const deviceListData = useSelector(getAllDevicesData);
  const [deviceListData, setDeviceListData] = useState([]);
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState({});

  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);

  // if (
  //   (loggedInUserRole === roles.ROLE2PORTAL ||
  //     loggedInUserRole === roles.ROLE3PORTAL ||
  //     loggedInUserRole === roles.ROLE7PORTAL) &&
  //   Array.isArray(getCustomerOrgData()) &&
  //   getCustomerOrgData()[0]
  // ) {
  //   let policies = getCustomerOrgData()[0]?.policies;
  //   let updatedPoliciesData = Utils.mapUserPolicies(DefaultPolicies, policies);
  //   setCustomerOrgPolicies(updatedPoliciesData);
  // }
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const {
    getLicenses,
    setLicenses,
    getLicensesExpiredPopUpShow,
    setLicensesExpiredPopUpShow,
    setExpiredLicensesInStore,
  } = useLicensesStore();
  const TOSPolicyAcceptionFlag = useSelector(getTOSPolicyAcceptionFlag);
  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    if (orgId === org?.orgId) {
      setOrgDetails(org || {});
    }
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === "selectedOrganization") {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDeviceListData(devices);
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    const getOrgData = async () => {
      const orgs = await getCustomerOrgData();
      if (
        loggedInUserData?.orgType === AppDefaults?.ORG_TYPE_CUSTOMER &&
        Array.isArray(orgs) &&
        orgs[0]
      ) {
        let policies = orgs[0]?.policies;
        let updatedPoliciesData = Utils.mapUserPolicies(
          DefaultPolicies,
          policies
        );
        setCustomerOrgPolicies(updatedPoliciesData);
      }
    };
    getOrgData();
  }, []);

  useEffect(() => {
    if (locationDetails?.length) {
      fetchLicensesData();
      fetchData();
    }
  }, [locationDetails]);

  const copyToOrgId = () => {
    navigator.clipboard.writeText(orgId);
    setTooltipVisible(true);
    setHoverTooltipOrgIDVisible(true);
    // Hide the tooltip after a short delay
    setTimeout(() => {
      setTooltipVisible(false);
    }, 1500);
  };

  const copyToOrgName = () => {
    navigator.clipboard.writeText(orgDetails?.shortName);
    setTooltipOrgNameVisible(true);
    setHoverTooltipOrgNameVisible(true);
    // Hide the tooltip after a short delay
    setTimeout(() => {
      setTooltipOrgNameVisible(false);
    }, 1500);
  };

  useEffect(() => {
    getApps(`partner/apps`);
    document.body.classList.add("bodycustom");
    getLocations();
    return () => {
      document.body.classList.remove("bodycustom");
    };
  }, []);

  const createSession = (redirectUrl) => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const expireIn = keycloak?.tokenParsed?.exp - currentTime;
    const refreshExpireIn = keycloak?.refreshTokenParsed?.exp - currentTime;
    const reqBody = {
      expires_in: expireIn,
      refresh_expires_in: refreshExpireIn,
      refresh_token: keycloak?.refreshToken,
    };
    const config = {
      credentials: "include",
      withCredentials: true,
    };
    axios.post("user/session", reqBody, config).then((res) => {
      if (res.status === 200) {
        window.open(redirectUrl, "_blank");
      }
    });
  };

  const handleNavigation = async (appData) => {
    if (appData) {
      let redirectUrl = "";
      if (
        appData.id === "oncloud" &&
        (loggedInUserRole === roles.ROLE1PORTAL ||
          loggedInUserRole === roles.ROLE4PORTAL ||
          loggedInUserRole === roles.ROLE5PORTAL ||
          loggedInUserRole === roles.ROLE6PORTAL)
      ) {
        redirectUrl = `${appData?.url}/customers/dashboard.html?orgId=${orgId}&orgName=${orgName}`;
      } else {
        redirectUrl = `${appData?.url}?orgId=${orgId}`;
      }
      await createSession(redirectUrl);
    }
  };
  const AppItem = ({ item, index }) => {
    const [hover, setHover] = useState(true);
    const [isClciked, seIsClciked] = useState(false);
    const mouseOver = (event) => {
      setHover(false);
      if (isClciked) {
        seIsClciked(false);
      }
    };
    const mouseOut = (event) => {
      setHover(true);
    };

    const handleClicked = () => {
      seIsClciked(true);
    };
    const handleRel = () => {
      seIsClciked(false);
    };

    return (
      <div
        onMouseEnter={mouseOver}
        onMouseLeave={mouseOut}
        role="button"
        className="landing-page-tile"
        onClick={() => handleNavigation(item)}
        key={`${index}-${item.name}`}
        onMouseDown={handleClicked}
        onMouseUp={handleRel}
      >
        <div
          className={
            !hover && !isClciked
              ? "app-card-hover"
              : !hover && isClciked
              ? "app-card-clicked"
              : "app-card"
          }
        >
          <img
            src={hover ? item.wicon ?? item.icon : item.sicon ?? item.gicon}
            className="card-img-top mx-auto appImage"
            alt="No Image"
          />
          <div className="card-body pt-0 white-hr">
            <h5 className="fw-bold appName">{item.name}</h5>
            <p className="appDesc">{item.desc}</p>
          </div>
        </div>
      </div>
    );
  };

  const getLocations = async () => {
    await axios
      .get(`partner/orgs/${orgId}/locations`, Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === "200")
          ) {
            const data = [...responseData];
            const defaultLocation = data?.filter(
              (location) => location.isDefault === "true"
            );
            const finalData = data.sort(function (x, y) {
              return x === defaultLocation[0]
                ? -1
                : y === defaultLocation[0]
                ? 1
                : 0;
            });
            dispatch(setLocationsData(finalData));
          }
        }
      });
  };

  const fetchLicensesData = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);
    let selectedOrg;
    if (!orgDetails?.orgId) {
      const orgId = searchParams.get("orgId");
      const org = await getSelectedOrganization();
      if (orgId === org?.orgId) {
        selectedOrg = org;
        setOrgDetails(org || {});
      }
    }
    if (licensesLoadSuccess?.status === "success") {
      setLicensesData(getLicenses());
      if (
        selectedOrg?.orgId &&
        actionExpiredLicenseWarning === false &&
        !warningShown
      ) {
        const allLicenses = getLicenses();
        const currentTimestamp = Date.now();
        const isPopUpClicked = localStorage.getItem("expired-license-action");
        if (
          allLicenses?.length > 0 &&
          getLoggedInUserPolicies()?.view_license_exp_msg &&
          selectedOrg?.orgType === AppDefaults.ORG_TYPE_CUSTOMER &&
          isPopUpClicked === "false"
        ) {
          const filteredExpiredLicenses = allLicenses.filter(
            (item) =>
              item.licenseStatus === constants.DEVICES_EXPIRED_DEVICE_STATUS &&
              item.expiryDate > currentTimestamp &&
              item.deviceId &&
              item.offeringType !== constants.LICENSE_ONBOARDING_STATUS &&
              item.offeringType !== constants.LICENSE_EVAL_STATUS
          );
          // filter and ignore those licenses which has another ACTIVE liecnses stacked
          const expiredLicensesDeviceIds = filteredExpiredLicenses?.map(
            (item) => item.deviceId
          );
          const activeLicensesData = allLicenses?.filter(
            (item) =>
              item.licenseStatus === constants.DEVICES_ACTIVE_DEVICE_STATUS &&
              expiredLicensesDeviceIds?.includes(item.deviceId)
          );
          const activeLicensesDeviceIds = activeLicensesData?.map(
            (item) => item.deviceId
          );
          const filteredExpiredLicensesData = filteredExpiredLicenses?.filter(
            (item) => !activeLicensesDeviceIds?.includes(item.deviceId)
          );
          if (filteredExpiredLicensesData?.length > 0) {
            setShowExpiredLicenseWarning(true);
            setWarningShown(true);
            setFilteredExpiredLicenses(filteredExpiredLicensesData);
            setExpiredLicensesInStore(filteredExpiredLicensesData);
          } else {
            setShowExpiredLicenseWarning(false);
          }
        }
      }
    }
  };

  const getLicensesStatus = (device) => {
    const deviceLicense = licensesData.filter(
      (license) => license.deviceId === device.deviceId
    )[0];
    if (
      deviceLicense?.expiryDate &&
      Utils.getDateDifferenceInDays(
        deviceLicense?.expiryDate,
        moment().valueOf()
      ) <= 0
    ) {
      return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED;
    } else {
      if (deviceLicense?.licenseStatus === "ASSIGNED_ACTIVATION_PENDING") {
        return (
          constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
          " " +
          moment(deviceLicense?.activeDate).format("MMM Do YYYY")
        );
      } else if (
        deviceLicense?.licenseStatus !== "UNASSIGNED" &&
        deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense.expiryDate,
          moment().valueOf()
        ) <= deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense.expiryDate,
          moment().valueOf()
        ) >= 0
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON;
      } else if (
        deviceLicense?.licenseStatus === "ACTIVE_UNASSIGNED" ||
        deviceLicense?.licenseStatus === "UNASSIGNED"
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE;
      } else if (deviceLicense?.licenseStatus === "ACTIVE") {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE;
      } else if (deviceLicense?.licenseStatus === "UNASSIGNED") {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      } else {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      }
    }
  };

  const addDeviceProperties = (groupedDeviceData) => {
    groupedDeviceData?.length &&
      groupedDeviceData?.forEach((device) => {
        device["displayDeviceStatus"] = Utils.getDeviceStatus(
          device.deviceStatus,
          device.connectionStatus
        );
        device["isFirmwareUpgradeAvailable"] =
          device?.properties && device?.properties["avlbl-duclo-ver"]
            ? device?.properties["avlbl-duclo-ver"] !==
              device?.properties["edge-app-version"]
            : false;
        device["displayLicensesStatus"] = getLicensesStatus(device);
        device["displayArea"] = locationDetails?.map((location) => {
          if (location.locationId === device.locationId) {
            return location?.areas?.map((area) => {
              if (area.areaId === device.areaId) {
                return area.areaName;
              }
            });
          }
        });
        device["displayLocation"] = locationDetails?.map((location) => {
          if (location.locationId === device.locationId) {
            return location.locationName;
          }
        });
        device.childDevices &&
          device.childDevices.forEach((child) => {
            child["displayDeviceStatus"] = Utils.getDeviceStatus(
              child.deviceStatus,
              child.connectionStatus
            );
            child["isFirmwareUpgradeAvailable"] =
              child?.properties && child?.properties["avlbl-duclo-ver"]
                ? child?.properties["avlbl-duclo-ver"] !==
                  child?.properties["edge-app-version"]
                : false;
            child["displayLicensesStatus"] = getLicensesStatus(child);
            child["displayArea"] = locationDetails?.map((location) => {
              if (location.locationId === child.locationId) {
                return location?.areas?.map((area) => {
                  if (area.areaId === child.areaId) {
                    return area.areaName;
                  }
                });
              }
            });
            child["displayLocation"] = locationDetails?.map((location) => {
              if (location.locationId === child.locationId) {
                return location.locationName;
              }
            });
          });
      });
    dispatch(setFilteredDevicesData(groupedDeviceData));
  };

  const getCapabiltiesFromDeviceList = async (data) => {
    let deviceList = [...data];
    let newDeviceList = deviceList?.map(async (device, index) => {
      let deviceInfo;
      if (device?.capability) {
        const parentDevice = deviceListData?.find(
          (parents) => parents?.deviceId === device?.gatewayId
        );
        if (parentDevice?.childDevices?.length) {
          deviceInfo = parentDevice?.childDevices?.find(
            (childs) => childs?.deviceId === device?.deviceId
          );
        }
      }
      // let isCapabilityCallRequired =
      //   deviceInfo?.capDetails === undefined ||
      //   deviceInfo?.capability?.version !== device?.capability?.version;

      // if (device?.capability && isCapabilityCallRequired) {
      //   let response = await fetch(device.capability.url);
      //   if (response.status === 200) {
      //     let responseJson = await response.json();
      //     return { ...device, capDetails: responseJson };
      //   } else {
      //     return { ...device };
      //   }
      // } else
      if (
        device?.capability &&
        deviceInfo?.capability?.version === device?.capability?.version &&
        deviceInfo.capDetails
      ) {
        return { ...device, capDetails: deviceInfo?.capDetails };
      } else {
        return { ...device };
      }
    });
    const deviceCapability = await Promise.all(newDeviceList);
    await setAllDevicesData(JSON.parse(JSON.stringify(deviceCapability)));
    const groupedDeviceData = Utils.getGroupedDevices(deviceCapability);
    addDeviceProperties(groupedDeviceData);
  };

  const fetchData = async () => {
    await axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;

          const updatedResponseData =
            responseData &&
            responseData?.map((item) => {
              if (
                !(
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO in
                  item?.apps
                )
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO
                ] = false;
              }
              if (
                !(
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI in
                  item?.apps
                )
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI
                ] = false;
              }
              if (
                !(
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND in
                  item?.apps
                )
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND
                ] = false;
              }
              if (
                !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS in item?.apps)
              ) {
                item.apps[
                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                ] = false;
              }
              return item;
            });
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === "200")
          ) {
            getCapabiltiesFromDeviceList(updatedResponseData);
            const updatedData = updatedResponseData.filter(
              (device) => device.deviceStatus !== "DEACTIVATED"
            );
            await setDevicesListOfCurrOrg(updatedData);
            await setCustomerOrgDevices(updatedResponseData);
          }
        }
      });
  };

  return (
    <>
      <Header showHeader={true}></Header>
      <div className="dasboard-background-Image">
        <PageWrapper className="main-wrapper">
          <div className="pt-1 pt-md-0 px-3 px-md-0 marginTop">
            <div className="border-start border-dark border-5  mt-3 mt-md-5 align-items-center">
              <h5 className="fw-bold ps-3 mb-0 orgTitle">{orgName}</h5>
              <div className="ps-3 fw-bold headingTitle">
                {constants.TEXT_ORGID_SHORTNAME_TITLE}
              </div>
              <div>
                <div class="d-flex flex-row justify-content-start gap-1">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      !hoverTooltipOrgIDVisible ? (
                        <Tooltip>{constants.TEXT_COPIED_ORGID_TITLE}</Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <button
                      type="button"
                      className="p-2 btn text-black-50 rounded-pill py-1 ps-0 px-2 border-0 d-flex ms-3 position-relative"
                    >
                      <small className="OrgId">
                        <span
                          onMouseOver={() => setHoverTooltipOrgIDVisible(false)}
                        >
                          {orgId}
                        </span>
                        <BtnCopy
                          onClick={() => copyToOrgId()}
                          className="btnCOpy"
                        ></BtnCopy>
                      </small>
                      {tooltipVisible && (
                        <div className="custom-tooltip">
                          {constants.DASHBOARD_TEXT_COPIED_TITLE}
                        </div>
                      )}
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      !hoverTooltipOrgNameVisible ? (
                        <Tooltip>{constants.TEXT_COPIED_ORGNAME_TITLE}</Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <button
                      type="button"
                      className="p-2 btn text-black-50 rounded-pill py-1 ps-0 px-2 border-0 d-flex ms-3 position-relative"
                    >
                      <small className="OrgId">
                        <span
                          onMouseOver={() =>
                            setHoverTooltipOrgNameVisible(false)
                          }
                        >
                          {" "}
                          {orgDetails?.shortName}
                        </span>
                        <BtnCopy
                          onClick={() => copyToOrgName()}
                          className="btnCOpy"
                        ></BtnCopy>
                      </small>
                      {tooltipOrgNameVisible && (
                        <div className="custom-tooltip">
                          {constants.DASHBOARD_TEXT_COPIED_TITLE}
                        </div>
                      )}
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="py-5">
              <div className="subMainTitle">
                {constants.LANDING_PAGE_WELCOME_MSG.substring(0, 7)}{" "}
                <span className="fw-bold">{loggedInUserData?.firstName}</span>
              </div>
              <h1 className="subHeadingTitle">
                {constants.LANDING_PAGE_WELCOME_MSG.substring(7)}
              </h1>
              <p className="pageDesc">{constants.LANDING_PAGE_DESCRIPTION}</p>
            </div>
            <div className="d-flex flex-row flex-wrap all-tile-div">
              {appList?.map((item, index) => (
                <AppItem item={item} index={index}></AppItem>
              ))}
            </div>
          </div>
          <SiteModal
            modalTitle={""}
            showModal={
              showExpiredLicenseWarning &&
              getLicensesExpiredPopUpShow() === true &&
              TOSPolicyAcceptionFlag
            }
            classes="device-license-expire-style"
          >
            <div className="text-center-expire-pop-up">
              <LicenseExpireWarning
                className="mb-4 icon-warning-color"
                size={96}
              />
            </div>
            <div className="heading-h5-bold">
              {constants.DEVICE_EXPIRED_LICENSE_CONTENT}
            </div>
            <div className="subheading-16px">
              {constants.DEVICE_LICESNSE_EXPIRE_SUBTITLE}
            </div>
            <>
              <div className="button-style">
                <PrimaryButton
                  className="btn btn-primary-outline mb-4"
                  type="button"
                  width="100%"
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--brand_white")}
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  backgroundColor="transparent"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  onClick={(e) => {
                    localStorage.setItem("expired-license-action", "true");
                    localStorage.setItem("expired-license-set-option", "true");
                    setLicensesExpiredPopUpShow(false);
                    navigate(
                      `/devices/listing.html?orgId=${orgDetails?.orgId}`,
                      {
                        state: { filteredExpiredLicenses },
                      }
                    );
                  }}
                >
                  {
                    constants.DEVICE_REVIEW_EXPIRE_LICENSES_DEVICE_MODAL_BUTTON_TITLE
                  }
                </PrimaryButton>
                <div className="button-dismiss-style">
                  <PrimaryButton
                    className="btn btn-primary-outline mb-4"
                    type="button"
                    width="100%"
                    borderWidth="1.5px"
                    hoverBorderWidth="1.5px"
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--primary_40")}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--primary_40")}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--brand_white")}
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--primary_40")}
                    backgroundColor="transparent"
                    height="56px"
                    fontSize="1.125rem"
                    lineHeight="24px"
                    onClick={() => {
                      localStorage.setItem("expired-license-action", "true");
                      setLicensesExpiredPopUpShow(false);
                      setShowExpiredLicenseWarning(false);
                      setActionExpiredLicenseWarning(true);
                    }}
                  >
                    {
                      constants.DEVICES_REVIEW_EXPIRED_LICENSES_DISMISS_BUTTON_TITLE
                    }
                  </PrimaryButton>
                </div>
              </div>
            </>
          </SiteModal>
        </PageWrapper>
      </div>
    </>
  );
}
export default LandingPage;
