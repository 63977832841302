import { useState, useEffect } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { PrimaryButton } from '../../../components/common';
import { Radio, RadioGroup } from '@mui/material';
import { usePoliciesStore } from '../../../store/policiesStore';
import { constants, DeviceTypeEnum } from '../../../helpers';
import { IoIosSearch } from 'react-icons/io';
import { LuGlobe } from 'react-icons/lu';
import { TiLocationOutline } from 'react-icons/ti';
import { FaArrowLeft } from 'react-icons/fa6';
import { getLocationsData } from '../../../store/reducers/AccountReducer';
import { useSelector } from 'react-redux';

const DiscoveredDevicesSetup = ({
  setShowDevcieSetupModal,
  selectedDevices,
  filteredDevices,
  onCompleteBulkSetUp,
}) => {
  const [step, setStep] = useState(1);
  const locationsData = useSelector(getLocationsData);
  const [filteredLocations, setFilteredLocations] = useState(locationsData);
  const [filteredAreas, setFilteredAreas] = useState([]);

  const [selectedDevicesObjs, setSelectedDevicesObjs] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const [searchLocationText, setSearchLocationText] = useState('');
  const [searchAreaText, setSearchAreaText] = useState('');
  const [nonIpcamDevcies, setNonIpcamDevcies] = useState([]);
  const [updateObject, setUpdateObject] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  useEffect(() => {
    updateDevcieObjects();
  }, []);

  useEffect(() => {
    onChangeSearchLocationText();
  }, [searchLocationText]);

  useEffect(() => {
    onChangeSearchAreaText();
  }, [searchAreaText]);

  const updateDevcieObjects = () => {
    const list = [];
    const nonIpcam = [];
    selectedDevices.forEach((deviceId) => {
      const device = filteredDevices.find((d) => d.deviceId === deviceId);
      if (device) {
        list.push(device);
        if (device.deviceType !== DeviceTypeEnum.IPCam.toLowerCase()) {
          nonIpcam.push(device);
        }
      }
    });
    setSelectedDevicesObjs(list);
    setNonIpcamDevcies(nonIpcam);
  };

  const onChangeSearchLocationText = () => {
    const newLocatins = locationsData.filter((location) =>
      location?.locationName
        ?.toUpperCase()
        .includes(searchLocationText.toUpperCase())
    );
    setFilteredLocations(newLocatins);
  };
  const onChangeSearchAreaText = () => {
    const newAreas = selectedLocation?.areas.filter((area) =>
      area?.areaName?.toUpperCase().includes(searchAreaText.toUpperCase())
    );
    setFilteredAreas(newAreas);
  };

  const onsetLocation = () => {
    const list = [];

    selectedDevicesObjs.forEach((device) => {
      const obj = {};
      obj.deviceId = device.deviceId;
      obj.locationId = selectedLocation?.locationId;
      list.push(obj);
    });
    setUpdateObject(list);
  };

  const onsetLocationArea = () => {
    const list = [];

    selectedDevicesObjs.forEach((device) => {
      const obj = {};
      obj.deviceType = device.deviceType;
      if (device.deviceType === DeviceTypeEnum.IPCam.toLowerCase()) {
        obj.deviceId = device.deviceId;
        obj.locationId = selectedArea.locationId;
        obj.areaId = selectedArea.areaId;
      } else {
        const defaultArea = selectedLocation?.areas.find(
          (area) => area?.isDefault
        );
        obj.deviceId = device.deviceId;
        obj.locationId = selectedLocation.locationId;
        obj.areaId = defaultArea?.areaId;
      }
      list.push(obj);
    });
    setUpdateObject(list);
  };
  return (
    <>
      <div className="device-setup-modal-wrapper">
        <div className="header-container">
          <div>
            <div
              className={`${
                step === 1 ? 'back-arrow-button-disabled' : 'back-arrow-button'
              }`}
              disabled={step === 1}
              onClick={() => {
                if (
                  step === 3 &&
                  nonIpcamDevcies.length < selectedDevicesObjs.length
                ) {
                  setStep(2);
                } else if (
                  step === 3 &&
                  nonIpcamDevcies.length === selectedDevicesObjs.length
                ) {
                  setStep(1);
                } else if (step === 2) {
                  setStep(1);
                }
              }}
            >
              <FaArrowLeft />
              {constants.DISCOVERED_DEVICES_BACK}
            </div>
            <div className="device-setup-modal-title">
              {constants.DISCOVERED_DEVICES_SETUP}
            </div>
          </div>
          <RxCross1
            className="site-modal-close"
            onClick={() => {
              setShowDevcieSetupModal(false);

              if (
                isSaved &&
                nonIpcamDevcies.length < selectedDevicesObjs.length &&
                selectedLocation &&
                selectedArea
              ) {
                onCompleteBulkSetUp(updateObject, 'update');
              } else if (
                isSaved &&
                nonIpcamDevcies.length === selectedDevicesObjs.length &&
                selectedLocation
              ) {
                onCompleteBulkSetUp(updateObject, 'update');
              } else if (isSaved && selectedLocation) {
                onCompleteBulkSetUp(updateObject, 'update');
              }
            }}
          />
        </div>
        {step === 1 ? (
          <div className="w-100">
            <div className="mb-4 mt-4">
              <div className="d-flex mb-1">
                <div className="step-icon"></div>
                <div className="step-icon-disabled"></div>
                {nonIpcamDevcies.length < selectedDevicesObjs.length ? (
                  <div className="step-icon-disabled"></div>
                ) : null}
              </div>
              <div className="steptext">
                {nonIpcamDevcies.length < selectedDevicesObjs.length
                  ? constants.DEVICES_STEP_ONE_OF_THREE
                  : constants.ORGANIZATION_LDAP_STEP_1_OF_2}
              </div>
            </div>
            <div>
              <div className="device-setup-modal-subTitle mb-3">
                {constants.DISCOVERED_DEVICES_SET_LOCATION}
              </div>
              <div className="search-container mb-3">
                <IoIosSearch className="search-icon" size={20} />
                <input
                  type={'text'}
                  placeholder={'Search Location'}
                  className="search-input"
                  onChange={(e) => setSearchLocationText(e?.target?.value)}
                />
              </div>
              <div className="location-conatiner">
                {filteredLocations.map((location) => (
                  <div className="location-item">
                    {location.locationName}
                    <RadioGroup
                      aria-labelledby="base-details-radio-buttons"
                      name="controlled-radio-buttons-group"
                    >
                      <Radio
                        sx={{
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_80'),
                        }}
                        checked={
                          selectedLocation?.locationId === location?.locationId
                        }
                        onChange={(e) => {
                          setSelectedLocation(location);
                          setSelectedArea(null);
                          setFilteredAreas(location.areas);
                        }}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                      />
                    </RadioGroup>
                  </div>
                ))}
              </div>
            </div>
            <PrimaryButton
              className="btn btn-primary mt-4"
              type="submit"
              width="100%"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              height="44px"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="600"
              disabled={!selectedLocation}
              onClick={() => {
                setIsSaved(true);
                if (nonIpcamDevcies.length < selectedDevicesObjs.length) {
                  setStep(2);
                  setSearchLocationText('');
                  onsetLocation();
                } else {
                  setStep(3);
                  onsetLocationArea();
                }
              }}
            >
              {nonIpcamDevcies.length < selectedDevicesObjs.length ? (
                <>{constants.DISCOVERED_DEVICES_NEXT}</>
              ) : (
                <>{constants.DISCOVERED_DEVICES_SAVE}</>
              )}
            </PrimaryButton>
          </div>
        ) : null}
        {nonIpcamDevcies.length < selectedDevicesObjs.length && step === 2 ? (
          <div className="w-100">
            <div className="mb-4 mt-4">
              <div className="d-flex mb-1">
                <div className="step-icon"></div>
                <div className="step-icon"></div>
                <div className="step-icon-disabled"></div>
              </div>
              <div className="steptext">
                {constants.DEVICES_STEP_TWO_OF_THREE}
              </div>
            </div>
            <div>
              <div className="w-100 d-flex justify-content-between mb-3">
                <div className="device-setup-modal-subTitle">
                  {constants.DISCOVERED_DEVICES_SET_AREA}
                </div>
                <div className="d-flex">
                  <div className="selected-opt">
                    <LuGlobe size={16} />
                    {selectedLocation.locationName}
                  </div>
                </div>
              </div>
              <div className="search-container mb-3">
                <IoIosSearch className="search-icon" size={20} />
                <input
                  type={'text'}
                  placeholder={'Search Area'}
                  className="search-input"
                  onChange={(e) => setSearchAreaText(e?.target?.value)}
                />
              </div>
              <div className="location-conatiner">
                {filteredAreas?.map((area) => (
                  <div className="location-item">
                    {area.areaName}
                    <RadioGroup
                      aria-labelledby="base-details-radio-buttons"
                      name="controlled-radio-buttons-group"
                    >
                      <Radio
                        sx={{
                          color: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_80'),
                        }}
                        checked={selectedArea?.areaId === area?.areaId}
                        onChange={(e) => {
                          setSelectedArea(area);
                        }}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                      />
                    </RadioGroup>
                  </div>
                ))}
              </div>
            </div>
            <PrimaryButton
              className="btn btn-primary mt-4"
              type="submit"
              width="100%"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              height="44px"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="600"
              disabled={!selectedArea || !selectedLocation}
              onClick={() => {
                setStep(3);
                onsetLocationArea();
                setIsSaved(true);
                setSearchAreaText('');
              }}
            >
              <>{constants.DISCOVERED_DEVICES_SAVE}</>
            </PrimaryButton>
          </div>
        ) : null}
        {step === 3 ? (
          <div className="w-100">
            <div className="mb-4 mt-4">
              <div className="d-flex mb-1">
                <div className="step-icon"></div>
                <div className="step-icon"></div>
                {nonIpcamDevcies.length < selectedDevicesObjs.length ? (
                  <div className="step-icon"></div>
                ) : null}
              </div>
              <div className="steptext">
                {nonIpcamDevcies.length < selectedDevicesObjs.length
                  ? constants.DEVICES_STEP_THREE_OF_THREE
                  : constants.ORGANIZATION_LDAP_STEP_2_OF_2}
              </div>
            </div>
            <div>
              <div className="w-100 d-flex justify-content-between">
                <div className="device-setup-modal-subTitle">Add Devcies</div>
                <div className="d-flex">
                  <div className="selected-opt">
                    <LuGlobe size={16} />
                    {selectedLocation?.locationName}
                  </div>
                  <div className="selected-opt">
                    <TiLocationOutline size={16} />
                    {selectedArea?.areaName}
                  </div>
                </div>
              </div>
              <div className="discription">
                {constants.DISCOVERED_DEVICES_ADD_MESSAGE}
              </div>
            </div>
            <PrimaryButton
              className="btn btn-primary mt-4"
              type="submit"
              width="100%"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              height="44px"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="600"
              onClick={() => {
                onCompleteBulkSetUp(updateObject, 'add');
                setShowDevcieSetupModal(false);
              }}
              disabled={!getCustomerOrgPolicies().install_device}
            >
              <>{constants.DISCOVERED_DEVICES_YES_ADD_DEVICES}</>
            </PrimaryButton>
          </div>
        ) : null}
        <div className="w-100  mt-2">
          <PrimaryButton
            className="btn btn-outline-secondary w-100"
            type="submit"
            loaderClassName="delete-view-loader"
            height="44px"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_96')}
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            onClick={() => {
              setShowDevcieSetupModal(false);
              if (
                isSaved &&
                nonIpcamDevcies.length < selectedDevicesObjs.length &&
                selectedLocation &&
                selectedArea
              ) {
                onCompleteBulkSetUp(updateObject, 'update');
              } else if (
                isSaved &&
                nonIpcamDevcies.length === selectedDevicesObjs.length &&
                selectedLocation
              ) {
                onCompleteBulkSetUp(updateObject, 'update');
              } else if (isSaved && selectedLocation) {
                onCompleteBulkSetUp(updateObject, 'update');
              }
            }}
          >
            {step === 3 ? (
              <>{constants.DISCOVERED_DEVICES_SKIP_STEP}</>
            ) : (
              <>{constants.DISCOVERED_DEVICES_CANCEL}</>
            )}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default DiscoveredDevicesSetup;
